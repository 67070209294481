/* ==================================== Utility classes ==================================== */

body {
  background-color: #f5f5f5;
}

#root {
  height: 100%;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.cursor-grab {
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.fs-9 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

.fs-7 {
  font-size: 0.7rem !important;
}

.fs-4 {
  font-size: 0.4rem !important;
}

.display--none {
  display: none;
}

.with-shadow {
  box-shadow: 0px 10px 20px 0px rgba(150, 157, 171, 0.20);
  transition: box-shadow 0.3s ease-in-out;
}

.nav-items {
  min-width: 220px;
  display: block;

  a.item,
  div.item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: start;
    padding: 1.5em 1em !important;
    margin-left: 1em;
  }

  a.item:hover,
  div.item:hover {
    cursor: pointer;
  }

  &:last-of-type {
    border-top: 1px solid #d7dbe3 !important;
  }
}

.ui.labeled.icon.menu .nav-items div.active.item {
  background: #f5f5f5;
  border-left: 3px solid #2e2d29;
  margin-left: 0;
}

.nav-items {
  div.active.item {
    span {
      color: #2e2d29;
      padding: 0 0.75em 0 1.5em;
      white-space: nowrap;
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.2px;
    }

    &::before {
      content: '';
      background: rgba(255, 255, 255, 0);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 30px;
    }

    .menu-icon {
      background: #2e2d29;
    }
  }

  a.item span br,
  div.active.item span br {
    display: none;
  }

  a.item span {
    align-self: center;
    padding: 0 0.75em 0 1.5em;
    font-family: 'IBM Plex Sans';
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.2px;
    color: #969dab;
    white-space: nowrap;
    width: 100%;
    text-align: left;
  }
}

.ui.labeled.icon.menu {
  width: 255px;
  overflow-y: auto;
  margin-top: 115px;
  padding-bottom: 115px;
  background-color: rgb(255, 255, 255);
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Safari and Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
}

.nav-items a.item i,
.ui.labeled.icon.menu .item > .icon:not(.dropdown) {
  color: #969dab;
  margin: 0 !important;
  width: 17.5px;
  height: 17.5px;
}

.nav-items a.item i {
  font-size: 17.5px;
  vertical-align: text-top;
}

.ui {
  &.labeled.icon.menu {
    .item > .icon:not(.dropdown)::before {
      font-size: 17.5px;
      vertical-align: text-top;
    }

    .active.item > .icon:not(.dropdown) {
      margin-left: 0.6em !important;
      color: #2e2d29;
    }
  }

  &.header > .content {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #2e2d29;
  }
}

h3.ui.header > .sub.header {
  margin-top: 13px;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
}

.headerDivider {
  border-left: 1px solid #d5d6d8;
  height: 25px;
  display: inline-block;
}

.ui.three.steps {
  display: none;
}

.coming-soon-btn.one-click-btn {
  background-color: #c1c1c1 !important;
  color: #212121 !important;
  pointer-events: initial !important;

  &:hover {
    cursor: not-allowed !important;
  }
}

.study-btn {
  padding: 0.8em 1.5em !important;
  background: #b1040f !important;

  > div {
    margin-left: 0 !important;
    margin-right: -2.5em !important;
  }
}

.register-container {
  text-align: center;
  margin-top: 5em;
}

.ui {
  &.red.restart-btn {
    background-color: #b1040f !important;
  }

  &.register-btn {
    background-color: #b1040f !important;

    a {
      color: #fff !important;
    }
  }

  &.basic.blue.button.authentication-btn {
    &:first-of-type:not(:last-of-type) {
      background-color: #b1040f !important;
      color: #fff !important;
      box-shadow: none !important;
    }

    &:last-of-type {
      color: #b1040f !important;
      box-shadow: inset 0 0 0 1px #b1040f !important;
    }
  }

  &.form .header .sub.header {
    width: 315px;
    margin: 1em auto !important;
    margin-bottom: 2em !important;
  }
}

.sdo-alert {
  align-items: center;
  background-color: #fff;
  border: 1px solid #8c1616;
  border-radius: 0.1875rem;
  display: block;
  padding: 0.5em 0.75em 0.4em;
  position: relative;
  color: #1b202f;
  max-width: 600px;
  margin-bottom: 3em;

  &::before {
    border-bottom-left-radius: 0.1875rem;
    border-left: 0.25em solid transparent;
    border-left-color: #8c1616 !important;
    border-top-left-radius: 0.1875rem;
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 0.25em;
  }
}

.sdo-alert--label {
  flex: 1;
  margin-left: 0.5em;
}

.sdo-alert--icon {
  color: #8c1616;
}

.mobile-only {
  display: none;
  background: transparent;

  h2 {
    letter-spacing: 0.4px !important;
    line-height: 31px !important;
    color: #2e2d29 !important;
    .sub.header {
      font-weight: 450 !important;
      line-height: 21px !important;
      color: #2e2d29 !important;
      letter-spacing: 0.4px !important;
    }
  }
}

.desktop-only {
  display: block;
}

@media screen and (max-width: 815px) {
  .login-form {
    background: #ffffff;
  }
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.admin-tab--container {
  border-bottom: 1px solid #d5d6d8;
  padding-bottom: 1em;
}

.admin-tab {
  background: transparent !important;

  &.active,
  &:hover {
    background: #eaeaed !important;
    cursor: pointer;
  }
}

.user-management {
  &.active {
    display: block;
  }

  &.hidden,
  &.active + .study-management {
    display: none;
  }
}

.study-management.active {
  display: block;
}

.user-management form,
.study-management form {
  max-width: 700px;
}

.user-management label,
.study-management label {
  display: block;
}

.user-management label + div,
.study-management label + div {
  display: inline-block;
  width: 450px;
}

.user-management button,
.study-management button {
  display: inline-block;
}

.margin-top--double {
  margin-top: 2em !important;
}

.color-orange {
  color: #f2711c !important;
}

.color-brown {
  color: #a5673f !important;
}

.color-grey {
  color: #767676 !important;
}

.color-green {
  color: #21ba45 !important;
}

.color-red {
  color: #9f3a38 !important;

  /* #db2828 !important; */
}

.color-blue {
  color: #2185d0 !important;
}

.border-grey {
  border-color: #d4d4d5 !important;
}

.op-3 {
  opacity: 0.3 !important;
}

.op-45 {
  opacity: 0.45 !important;
}

.op-65 {
  opacity: 0.65 !important;
}

.mt75 {
  margin-top: 0.75rem !important;
}

.box-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.bg-lightgreen {
  background-color: #f7fcfa !important;
}

.undo-line-height {
  line-height: inherit !important;
}

.zindex-1500 {
  z-index: 1500 !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.min-height-55-px {
  min-height: 55px !important;
}

.min-width-735-px {
  min-width: 735px !important;
}

.min-width-350-px {
  min-width: 350px !important;
}

.width-580-px {
  width: 580px !important;
}

.width-500-px {
  width: 500px !important;
}

.width-350-px {
  width: 350px !important;
}

.width-300-px {
  width: 300px !important;
}

.width-280-px {
  width: 280px !important;
}

.width-260-px {
  width: 260px !important;
}

.width-230-px {
  width: 230px !important;
}

.width-200-px {
  width: 200px !important;
}

.width-140-px {
  width: 140px !important;
}

.width-110-px {
  width: 110px !important;
}

.width-100-px {
  width: 100px !important;
}

.width-90-px {
  width: 90px !important;
}

.width-80-px {
  width: 80px !important;
}

.width-70-px {
  width: 70px !important;
}

.width-60-px {
  width: 60px !important;
}

.width-40-px {
  width: 40px !important;
}

.width-24-px {
  width: 24px !important;
}

.height-22-px {
  height: 22px !important;
}

.height-60-px {
  height: 60px !important;
}

.height-50-px {
  height: 50px !important;
}

.line-height-20-px {
  line-height: 20px !important;
}

.ellipsis {
  /* height: 50px; */
  text-overflow: ellipsis;

  /* required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.breakout {
  /* see https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
  /* These are technically the same, but use both */
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  -ms-word-break: break-all !important;

  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all !important;
}

/* ==================================== Semantic UI Overrides ==================================== */

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (min-width: 1200px) {
  .ui.container {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.error {
  .field,
  &.field,
  .label,
  .field.label {
    color: #9f3a38 !important;
  }
}

.ui {
  &.form .error .field > label {
    color: #9f3a38 !important;
  }

  &.header {
    .error,
    &.error {
      color: #9f3a38 !important;
    }
  }
}

.error {
  .text,
  &.text {
    color: #9f3a38 !important;
  }

  .ui.basic.label {
    color: #9f3a38 !important;
    background: none #fff !important;
    border-color: #9f3a38 !important;
  }
}

.ui.form .error textarea {
  background: #fff6f6;
  border-color: #e0b4b4;
}

.disabled {
  .ui {
    &.basic.label,
    &.header {
      pointer-events: none;
      opacity: 0.45 !important;
    }
  }

  .field,
  textarea {
    pointer-events: none;
    opacity: 0.45 !important;
  }
}

/* ====================================== Overwrites ========================================= */

.ui {
  &.secondary.pointing.menu .active.item {
    border-color: #b1040f;

    &:hover {
      border-color: #b1040f;
    }
  }

  &.right.floated.buttons {
    float: none;

    .ui {
      &.button {
        color: #000 !important;
        text-shadow: none !important;
        background: transparent !important;
        box-shadow: none !important;
        border-bottom: 1px solid #d5d6d8;
        border-radius: initial;
      }

      &.active.button,
      &.button:hover {
        border-bottom: 3px solid #b1040f;
      }
    }
  }

  &.red.mini.top.left.attached.label,
  &.green.mini.top.left.attached.label,
  &.orange.mini.top.left.attached.label,
  &.grey.mini.top.left.attached.label {
    top: 1em;
    left: 1em;
    border-radius: 0.25em;
    padding: 0.85em 1.25em;
  }

  &.basic {
    &.red.button,
    &.green.button,
    &.orange.button {
      box-shadow: none !important;
      padding: 0.78571429em 1em !important;
    }
  }

  &[class*='right labeled'].icon.button {
    padding-right: 4.07142857em !important;
    padding-left: 1.5em !important;
  }

  &.basic {
    &.red.button:hover {
      background-color: #faf1f3 !important;
      box-shadow: inset 0 0 0 1px #f52d2d !important;
      color: #f94545 !important;
    }

    &.green.button:hover {
      background-color: #72ef9024 !important;
      box-shadow: inset 0 0 0 1px #42d064 !important;
      color: #42d064 !important;
    }

    &.orange.button:hover {
      background-color: #f996551a !important;
      box-shadow: inset 0 0 0 1px #f99655 !important;
      color: #f99655 !important;
    }
  }
}

.ml-auto {
  margin-left: auto !important;
}

.clear--both {
  clear: both;
}

.flex-auto.study-connect-btns {
  min-width: 280px !important;
}

.flex-wrapper--row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex-wrapper--row {
  &.workspaces-flex {
    .attached.label {
      position: relative;
    }

    .headerDivider {
      margin-left: 0.5em;
      margin-right: 0.25em;
      margin-top: 0.6em;

      &.buttonsDivider {
        margin-left: 2em;
        margin-right: 0.25em;
        margin-top: 0.6em;
      }
    }

    .env-buttons--wrapper {
      display: flex !important;
      flex-direction: row;

      /* margin-left: 1em; */
      justify-content: end;
      flex-grow: 1;

      + .fadeIn.animated {
        width: 100%;
      }
    }
  }

  &.workspaces.flex .clearfix {
    &::after,
    &::before {
      content: none;
      display: none !important;
    }
  }

  &.workspaces-flex .clearfix .floated.button {
    float: none !important;
  }
}

.workspace-details--wrapper {
  margin-bottom: 1em;
}

.workspace-details--label {
  color: #595959;
  font-size: 0.9em;

  /* margin-bottom: .5em; */
}

.workspace-details--content {
  border: 1px solid #dededf;
  background-color: #f2f2f2;
  width: fit-content;
  padding: 0.45em 0.95em;
  border-radius: 0.25em;
  margin-top: 0.25em;

  /* min-width: 150px; */
}

.textarea--wrapper {
  position: relative;
  width: fit-content;
}

.workspace-details--content {
  &.textarea {
    resize: none;
    display: inline-block;
    white-space: nowrap;
    min-width: 420px;
    line-height: 0.5em;
    padding-top: 1em;
  }

  &:hover {
    cursor: not-allowed;
  }
}

.workspace-details--button {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  border: none;
  background-color: #d4cece;
  border-radius: 0.25em;
  padding: 0.45em 0.75em;

  &:hover {
    cursor: pointer;
  }
}

.modal--survey {
  height: 50vh;

  > .content {
    height: 100%;

    > .description {
      max-width: 100%;
    }
  }
}

.one-click-btn {
  background-color: #b1040f !important;
  color: #fff !important;

  > {
    div {
      margin-right: -3em;
      margin-left: 1em;
    }

    .icon {
      left: 0 !important;
    }
  }

  &:disabled:hover {
    cursor: not-allowed;
  }
}

.ui {
  &.attached.warning.message,
  &.warning.message,
  &.visible.visible.visible.visible.message {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }

  &.segment,
  &.raised.segment {
    box-shadow: none;
  }

  &.blue.segment:not(.inverted) {
    border-top-color: #b1040f !important;
  }
}

.flex-layout {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

input[type='text'] {
  display: inline-block;
  width: 500px;
  height: 40px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 10px 10px 10px 100px;
  transition: all 0.1s ease-out;

  + label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    color: white;
    border-radius: 3px 0 0 3px;
    padding: 0 20px;
    background: #e03616;
    transform: translateZ(0) translateX(0);
    transition: all 0.3s ease-in;
    transition-delay: 0.2s;
  }

  &:focus {
    + label {
      transform: translateY(-120%) translateX(0%);
      border-radius: 3px;
      transition: all 0.1s ease-out;
    }

    padding: 10px;
    transition: all 0.3s ease-out;
    transition-delay: 0.2s;
  }
}

.text-input {
  position: relative;
  margin-top: 50px;
}

.custom-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-card-header {
  display: flex;
  justify-content: space-between;
}

#file-upload-button {
  background: #4581af;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: inset 0 0 0 0 transparent;
}

.learn-icon {
  -webkit-mask-image: url(../images/learning.svg);
  mask-image: url(../images/learning.svg);
}

.research-icon {
  -webkit-mask-image: url(../images/research.svg);
  mask-image: url(../images/research.svg);
}

.data-icon {
  -webkit-mask-image: url(../images/data.svg);
  mask-image: url(../images/data.svg);
}

.jobs-icon {
  -webkit-mask-image: url(../images/jobs.svg);
  mask-image: url(../images/jobs.svg);
}

.workflow-icon {
  -webkit-mask-image: url(../images/workflow.svg);
  mask-image: url(../images/workflow.svg);
}

.manage-icon {
  -webkit-mask-image: url(../images/manage.svg);
  mask-image: url(../images/manage.svg);
}

.contact-icon {
  -webkit-mask-image: url(../images/contact.svg);
  mask-image: url(../images/contact.svg);
}

.menu-icon {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background: transparent;
  background: #969dab;
  width: 20px !important;
  height: 20px !important;
}

.close {
  position: absolute;
  background: url('../images/close.svg');
  width: 13px;
  height: 13px;
  right: 16px;
  top: 16px;
  cursor: pointer;
  &.disabled {
    display: none;
  }
}

.fit.animated.fadeIn {
  margin: 115px 0px 0px 255px;
}

.terminate-modal {
  .actions {
    button {
      margin: 0px !important;
    }

    button:first-child {
      margin-bottom: 8px !important;
    }
  }
}

.custom-actions {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    font-family: 'IBM Plex Mono';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
    span {
      margin-right: 10px;
    }
  }

  &.login {
    button {
      font-weight: 700;
      font-family: 'IBM Plex Sans';
    }
  }

  button.primary-black {
    background: #2e2d29;
    border: none;
    color: #fafafa;

    &.disabled {
      background: #969dab;
      color: #fafafa;
    }
  }

  button.progress {
    color: #fafafa;
    background: #e98300;
    border: none;
    pointer-events: none;
    div {
      background: #7ea2c3;
      border-radius: 5px;
      padding: 4px 0px 4px 4px;
    }
  }

  button.primary-blue {
    background: #4298b5;
    border: none;
    color: #fafafa;

    &.disabled {
      background: #969dab;
      color: #fafafa;
    }
  }

  button.secondary {
    background: #ffffff;
    color: #2e2d29;
    cursor: pointer;
    border: 2px solid;

    &.disabled {
      background: #ffffff;
      color: #969dab;
    }
  }
}

.ui.page.modals.dimmer.transition.visible.active {
  background: rgba(46, 45, 41, 0.55);
}

.mxn6 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.pl6 {
  padding-left: 6rem !important;
}

.pr6 {
  padding-right: 6rem !important;
}